import React from 'react';

import RegistLoginLayout from '../components/site_layout/regist_login';
import AdminLogin from '../components/admin_login';

import SEO from "../components/seo";

const LoginPage = () => (
  <RegistLoginLayout>
    <SEO title='Login'></SEO>
    <AdminLogin></AdminLogin>
  </RegistLoginLayout>
);

export default LoginPage;
