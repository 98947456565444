import React, { useState } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { MobileOutlined, LockOutlined } from '@ant-design/icons';
import AxiosRequest from '../apis';

import './index.css';
import { setToken } from '../../assets/common_funcs/token_handle';
import { navigate } from 'gatsby';

// 管理员登陆

const AdminLogin = () => {
  const [requestCount, setRequestCount] = useState(0);
  const [reqParams] = useState({});
  const [reqObject, setReqObject] = useState({ 'mobile': '', 'password': '' });
  const api = 'adminer/v1/admintoken';
  const method = 'post';

  const responseDataHandle = (respData) => {
    // 接收request数据后操作
    try {
      console.log(respData);
      let token = respData.token ? respData.token : '';
      if (token.length > 15) {
        setToken(respData.token);
        // 跳回首页
        navigate('/');
      } else {
        setToken('');
      }
    } catch (error) {
      // 登陆失败
      console.log(error);
      setToken('');
    }
  };

  const onFinish = values => {
    // 表单提交操作
    setReqObject({ mobile: values.mobile, password: values.password });
    setRequestCount(requestCount + 1);  // 改变 requestCount 提交request请求
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="mobile"
        rules={[
          {
            type: 'string',
            max: 11,
            min: 11,
            required: true,
            message: 'Please input your Mobile!',
          },
        ]}
      >
        <Input prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="Mobile" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            min: 6,
            message: 'Password minLength 6!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="">
          Forgot password
        </a>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
      <Form.Item>
        <AxiosRequest
          responseDataHandle={responseDataHandle} api={api} method={method}
          reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
        ></AxiosRequest>
      </Form.Item>
    </Form>
  );
}

export default AdminLogin;
