import React from 'react';

import Footer from './footer';

import './index.css';
import 'antd/dist/antd.css';
import customConfig from '../../custom_config';

const RegistLoginLayout = ({ children }) => {
  const componyLogoUrl = customConfig.companyLogoUrl;

  return (
    <div>
      <div className="avatar-box">
        <img style={{ width: '100%' }} src={componyLogoUrl} alt="Logo" />
      </div>
      <div className='regist-login-box'>{children}</div>
      <Footer />
    </div>
  )
}

export default RegistLoginLayout;